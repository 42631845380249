<template>
    <div class="checkTask">
      <!-- top -->
      <el-breadcrumb separator="/">
          <el-breadcrumb-item>机构信息</el-breadcrumb-item>
          <el-breadcrumb-item @click.native="goback1">科室管理</el-breadcrumb-item>
          <el-breadcrumb-item>编辑科室</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="blueTitle" style="margin-left:12px;">科室管理</div>
      <div class="mian-box-side">
        <div class="main-box-inside">
            <div class="form">
                <div class="form-left">
                    <el-form ref="form" label-position="left" :model="formLeft" label-width="120px">
                        <el-form-item label="科室名称*">
                            <el-input v-model="formLeft.name" style="width:326px;height:44px"></el-input>
                        </el-form-item>
                        <el-form-item label="科室位置">
                            <el-input v-model="formLeft.addr" style="width:326px;height:44px"></el-input>
                        </el-form-item>
                        <el-form-item label="科室级别">
                            <el-select v-model="formLeft.level" @change="setParent" @visible-change="down1" style="width:326px;height:44px">
                                <el-option
                                    v-for="item in levelOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属科室一级" v-if="formLeft.level!='一级'">
                            <el-select v-model="formLeft.belong" @change="setParent1" @visible-change="down2" style="width:326px;height:44px">
                                <el-option
                                    v-for="item in deptList"
                                    :key="item.value"
                                    :label="item.deptName"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属科室二级" v-if="formLeft.level=='三级'">
                            <el-select v-model="formLeft.belong2" @change="setParent2" @visible-change="down3" style="width:326px;height:44px">
                                <el-option
                                    v-for="item in deptList2"
                                    :key="item.value"
                                    :label="item.deptName"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="form-right">
                    <el-form ref="form" label-position="left" :model="formLeft" label-width="100px">
                        <el-form-item label="科室介绍">
                            <el-input v-model="formLeft.produce" maxlength="24" show-word-limit style="width:326px;height:44px"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="botton">
                <div class="null"></div>
                <div class="full">
                    <div class="save" @click="save">保存</div>
                    <div class="cancel" @click="cancel">取消</div>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>
<script>
import {getHospitalList,viewDept,updateDept,getDeptList} from '@/api/order.js'

export default {
    

    data(){
        return{
        
          id:null,
          pid:null,
          deptList:[],
          deptList2:[],
          formLeft:{
            name:null,
            addr:null,
            level:null,
            belong:null,
            belong2:null,
            produce:null,
            hospitalId:null,
          },
          levelOptions:[
            {
            label:'一级',
            value: '一级',
            },
            {
            label:'二级',
            value: '二级'
            },
            {
            label:'三级',
            value:'三级'
            },
          ]
        }
    },
    created(){
      if(this.$route.query.id){
        this.id = this.$route.query.id;
        this.view(this.id);
         
      }
    },
    mounted(){
        this.getHoList();
    },
  
    methods:{
        //面包屑
        goback1(){
            this.$router.go(-1);
        },
       //查询医院列表
        getHoList(){
            getHospitalList({
               //
            }).then(res=>{
                if(res.data.status==200){
                    this.formLeft.hospitalId = res.data.data.id;
                }else{
                    this.$message.error(res.data.message);
                }
            })
        },
        //save
        save(){
            if((this.formLeft.name==null) && (this.formLeft.hospitalId==null)){
                this.$message.error("必填项不能为空!");
            }else{
                updateDept({
                    id:this.id,
                    pid:this.pid,
                    deptName:this.formLeft.name,
                    orgId:this.formLeft.hospitalId,
                    deptLevel:this.formLeft.level,
                    deptLocation:this.formLeft.addr,
                    description:this.formLeft.produce,
                }).then(res=>{
                    if(res.data.status==200){
                         this.$message({
                            message: '更新成功！',
                            type: 'success'
                        });
                        this.view(this.id);
                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            }
        },
        cancel(){
            this.clear();
        },
        clear(){
            this.formLeft.name=null;
            this.formLeft.addr=null;
            this.formLeft.level=null;
            this.formLeft.belong=null;
            this.formLeft.belong2=null;
            this.formLeft.produce=null;
            this.formLeft.hospitalId=null;
        },

        //查看科室
        view(id){
            viewDept({
                id:id
            }).then(res=>{
                if(res.data.status==200){
                    this.formLeft.name=res.data.data.deptName;
                    this.formLeft.produce=res.data.data.description;
                    this.formLeft.addr=res.data.data.deptLocation;
                    this.formLeft.level=res.data.data.deptLevel;
                    if(res.data.data.deptLevel=='二级'){
                        this.getDeptList(-1);
                        var pidList = res.data.data.parentDept;//[pid,id,deptName]
                        this.pid = pidList[0].pid;
                        //回显
                        this.formLeft.belong = pidList[0].id;
                    }
                    if(res.data.data.deptLevel=='三级'){
                        
                        var pidList1 = res.data.data.parentDept;//二级
                        var pidList0 = pidList1[0].parentDept;//一级
                        this.pid = pidList1[0].pid;
                        this.getDeptList(-1);
                        this.getDeptList2(this.pid);
                        //回显
                        this.formLeft.belong = pidList0[0].id;
                        this.formLeft.belong2 = pidList1[0].id;
                    }

                }else{
                    this.$message.error(res.data.message);
                }
            })
        },

        setParent(e){
            this.getDeptList(-1);
        },
        setParent1(e){
            this.pid= e;
            this.getDeptList2(this.pid);
        },
        setParent2(e){
            this.pid= e;
        },
        //下拉框时
        down1(){
            //console.log("dw1");
        },
        down2(){
            if(this.deptList.length==0){
                setTimeout(()=>{this.formLeft.level='一级';},1600)
            }
        },
        down3(){
            if(this.deptList2.length==0){
                setTimeout(()=>{this.formLeft.level='二级';},1600)
            }
        },
        //获取科室列表
        getDeptList(pid){
            getDeptList({
                pid:pid
            }).then(res=>{
                if(res.data.status==200){
                    var temp = res.data.data.records
                    this.deptList = temp;
                    if(temp.length==0){
                        this.pid = -1;
                    }
                }else{
                    this.$message.error(res.data.message);
                }
            })
        },
        getDeptList2(pid){
            getDeptList({
                pid:pid
            }).then(res=>{
                if(res.data.status==200){
                    var temp = res.data.data.records;
                    this.deptList2 = temp;
                    if(temp.length==0){
                        this.pid =pid;
                    }
                }else{
                    this.$message.error(res.data.message);
                }
            })
        }

    }
}
</script>
<style scoped lang="stylus">

//component style
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
/deep/.el-input__inner {

    border-radius: 0px !important;
    border: 1px solid #00cccc !important;
    height: 44px !important;
    line-height: 44px !important;
}
/deep/.el-form-item__label{
    color: #323233 !important;
    font-size: 16px !important;
}
.blueTitle
  font-size: 18px 
  color: #353535
  display: flex
  margin-top: 37px
  align-items: center 
  position: relative
  &::before
    width: 3px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:2px
    background-color: #00CCCC

.mian-box-side
    width: 100%
    box-sizing: border-box
    padding-right: 170px
    .main-box-inside
        width: 100%
        margin-top: 20px
        border: 1px solid #CEF8F8
        box-sizing: border-box
        padding-top: 60px 
        padding-right: 10%
        padding-left: 10%
        .form  
            display: flex
            justify-content: space-between
        .botton
            display: flex
            margin-top: 105px
            margin-bottom: 60px
            justify-content: space-between
            .null
                width: 160px 
                height: 44px 
            .full
                display: flex
                .save
                    width: 160px  
                    height: 44px 
                    line-height: 44px 
                    text-align: center
                    color: #fff
                    border-radius: 6px 
                    font-size: 18px 
                    background-color: #00cccc 
                .cancel
                    width: 160px  
                    height: 44px 
                    line-height: 44px 
                    text-align: center
                    color: #00cccc
                    border-radius: 6px 
                    font-size: 18px 
                    margin-left: 30px
                    border: 1px solid #00cccc
</style>